<template>
  <div>
    <BaseHeader />
    <div class="homeSlider">
      &nbsp;
      <div class="col-lg-12">
        <div class="home-text onMobile">
          <h1 class="desktopOnly">About Us</h1>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-container">
        <p>
          My Halal Restaurants is here now with premium Halal food-related
          services, which will continue to be sublime all over Canada. We are
          redefining the way of searching for “Halal food near me” by providing
          a comprehensive list of all Halal food providers in a single place.
          Our unique and quick services are making your world more efficient. We
          are a cornerstone in your Muslim community and offer an ever-enticing
          and zestful experience of various food options with a verily feeling
          of joy and happiness.
        </p>
        <p>
          “My Halal Restaurants” provides you with an unconventional and
          outstanding set of unique and top-quality services, a great list of
          Halal food like Halal Chinese restaurants, catering services, Halal
          grocery stores, Halal bakeries, Halal food trucks, Halal meat shops,
          Tiffin service, and Halal ice-cream cafes. Our services give you
          better control over your purchase pattern by providing the best
          <a href="https://myhalalrestaurants.com">Halal food options</a>. Get
          the best food delivery and takeout food services, and design your busy
          day's delight. We have a list of the best Halal lounges for lavish
          parties; enjoy shisha, tea and coffee in an excellent, cozy and
          welcoming environment that makes your food closer to your heart. Our
          utmost purpose is the happiness of our valuable Muslim community.
        </p>
        <p>
          Our Services in Canada make your meet-ups with family, friends, and
          dear ones memorable. Visit “My Halal Restaurants” and get the best
          answer to your query, “<a href="https://myhalalrestaurants.com/">
            Halal food near me. </a
          >”
        </p>
        <p>
          Enjoy a trendy and latest listing of your favourite food places with
          us. We are “Your Go-To Place for Everything Halal in Canada” that will
          make your days awesome.
        </p>
      </div>
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <div></div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';

export default {
  name: 'ContactUs',
  components: {
    BaseHeader,
    BaseFooter,
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      nameError: '',
      emailError: '',
      subjectError: '',
      contactFormCheck: false,
      successMessage: 'Thanks',
    };
  },
  mounted() {
    //const loadingOverlay = document.getElementById('loading');
    //loadingOverlay.style.display = 'none';
  },
  methods: {
    submitContactUs() {
      if (this.name == '') {
        this.nameError = 'Name is required.';
      } else {
        this.nameError = '';
      }
      if (this.email == '') {
        this.emailError = 'Email is required.';
      } else {
        // this.emailError = '';
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (regex.test(this.email)) {
          this.emailError = '';
        } else {
          //alert('Please enter valid email.');
          this.emailError = 'Please enter valid email.';
        }
      }
      if (this.subject == '') {
        this.subjectError = 'Subject is required.';
      } else {
        this.subjectError = '';
      }
      if (this.name != '' && this.email != '' && this.subject != '') {
        this.getLatestListings();
      }
    },
    async getLatestListings() {
      try {
        const response = await fetch(this.$apiUrl + 'contact_form', {
          method: 'POST',
          // headers: {
          //   // 'Content-Type': 'application/json',
          //   name: this.name,
          //   email: this.email,
          //   subject: this.subject,
          //   message: this.message,
          // },
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message,
          }),
        });
        //const response = await fetch('/api/citylist?name=' + this.cityDDModel);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data['results']['affectedRows']);
        if (data['results']['affectedRows'] == 1) {
          this.successMessage =
            'Thank you for contacting us. We will back to you soon!';
          this.contactFormCheck = true;
        }
        // console.log(data, 'zain');
        // document.getElementById('searchCityDiv').style.display = 'block';
        // this.citiesff = data;
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
// document.addEventListener(
//   'DOMContentLoaded',
//   function () {
//     document.getElementById('preloader').style.display = 'none';
//   },
//   false
// );
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.homeSlider {
  background-image: url('@/assets/about.png');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 260px;
}
.about {
  background-color: black;
  .about-container {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
      font-style: normal;
      a {
        color: #dc3545;
        font-weight: 500;
      }
    }
  }
}
.home-text {
  text-align: center;
  margin-top: 100px;
}

.home-text h1 {
  font-size: 45px;
  color: #fff;
  font-family: 'Anton', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 0 1px 0 #999, 0 2px 0 #888, 0 3px 0 #777, 0 4px 0 #666,
    0 5px 0 #555, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none;
}

@media screen and (max-width: 768px) {
  .homeSlider {
    height: 600px;
  }

  .home-text h1 {
    font-size: 70px;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .home-text h1 {
    font-size: 70px;
  }
}
</style>
