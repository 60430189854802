<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { reactive, provide } from 'vue';
export default {
  name: 'App',
  setup() {
    // Create a reactive global state object
    const globalState = reactive({
      selected_country_code: 'CA',
      selected_country_id: 1,
      selected_country: 'Canada',
      selected_country_listing_count: 1975,
    });

    // Provide the global state so that it's accessible to child components
    provide('globalState', globalState);

    return { globalState };
  },
  mounted() {},
};
</script>

<style lang="scss"></style>
