import { createApp, ref } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

// createApp(App).use(router).mount('#app');
let app = createApp(App);
app.config.globalProperties.$apiUrl =
  'https://planetautopart.com/server/app/api/';
app.config.globalProperties.$selected_country_code = 'CA';
app.config.globalProperties.$selected_country_id = 1;
app.config.globalProperties.$selected_country = 'Canada';
app.config.globalProperties.$selected_country_listing_count = 1975;
const myVariable = ref('Initial Value');

// Add it to globalProperties
app.config.globalProperties.$myVariable = myVariable;
app.use(router).mount('#app');
