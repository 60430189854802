<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!-- <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2115150.97184842!2d-115.02174414668933!3d51.999606814017625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2s!4v1716276670569!5m2!1sen!2s"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> -->
        <!-- <GoogleMap
          api-key="AIzaSyCNjuVuGdT_YPw9L1pOQt1-CwfGdDx_JMY"
          :center="{ lat: 37.7749, lng: -122.4194 }"
          :zoom="12"
          style="width: 100%; height: 600px"
        /> -->
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
//import { useSearchFilterStore } from '@/stores/searchFilter';
export default {
  setup() {
    const route = useRoute();
    const category = route.params.slug;
    const city = route.params.city;
    // const lati = ref(0);
    // const longi = ref(0);
    const lati = ref(0);
    const longi = ref(0);
    onBeforeMount(async () => {
      try {
        const response = await fetch(this.$apiUrl + 'citydetails/' + city, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        //const response = await fetch('/api/getpostdetail?slug=' + slug);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // console.log(data[0].lati, 'zzzz');
        lati.value = data[0].lati;
        longi.value = data[0].longi;

        // this.post_images2 = data[0].img;
        // console.log(lati, 'lati');

        // console.log(htmlContent.value);
        //console.log(data['data'], 'zain');
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    });
    return {
      category,
      city,
      lati,
      longi,
    };
  },
  data() {
    return {
      map: null,
      markers: [
        { lat: 40.7128, lng: -74.006, title: 'New York' },
        { lat: 34.0522, lng: -118.2437, title: 'Los Angeles' },
      ],
      listingSlug: this.$route.params.slug,
      latestListings: [{ id: 1, title: 'My journey with Vue' }],
    };
  },
  mounted() {
    // Initialize the map
    this.getLatestListings();
  },
  methods: {
    async getLatestListings() {
      let url = '';
      // var that = this;
      if (this.city != null) {
        url =
          this.$apiUrl +
          'getlistingagainstsearch/' +
          this.category +
          '/' +
          this.city;
      } else {
        url = this.$apiUrl + 'listingformcatgoryname/' + this.category;
      }
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.latestListings = data;
        this.initMap();
        //console.log(data['data'], 'zain');
        this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data';
      } finally {
        this.isLoading = false;
      }
    },
    initMap() {
      // Create a new map instance
      // console.log(this.lati.to, 'lati initmap');
      // alert(this.lati);
      if (this.lati == 0) {
        this.map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 57.0935711, lng: -122.7178396 }, // Set the initial center of the map
          zoom: 3, // Set the initial zoom level
        });
      } else {
        this.map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: parseFloat(this.lati), lng: parseFloat(this.longi) }, // Set the initial center of the map
          zoom: 13, // Set the initial zoom level
        });
      }
      console.log(window.location.origin, 'zzz');
      // Add markers
      this.latestListings.forEach(marker => {
        new window.google.maps.Marker({
          position: {
            lat: parseFloat(marker.geo_lat),
            lng: parseFloat(marker.geo_long),
          }, // Set marker position
          map: this.map, // Set the map to add the marker to
          icon: window.location.origin + '/map.png',
          title: marker.title, // Optional title for the marker
        });
      });
    },
  },
};
</script>

<style>
#map {
  height: 700px;
}
</style>
<style scoped>
iframe {
  margin-top: 60px;
  width: 100%;
  height: 900px;
}
</style>
