<template>
  <div>
    <BaseHeader />
    <div class="container-fluid details">
      <Carousel :images="post_images" />
      <!-- <div class="container-fluid">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img :src="categoriesLIst.img" class="d-block w-20" alt="..." />
          </div>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../static/image2.png" class="d-block w-20" alt="..." />
          </div>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../static/image3.png" class="d-block w-20" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div> -->
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <!-- <Breadcrumb /> -->
            <div class="details-title">
              <h1 v-html="pageTitle"></h1>
            </div>
            <!-- <div class="input-group mb-2">
          <div class="input-group-prepend">
            <img class="details-icon" src="/static/CardCategory.png" />
            <span class="details-text">Restaurant</span>
          </div>
        </div>
        <div class="listing-tags">
          <span class="listing-tag">Pakistani</span>
          <span class="listing-tag">BBQ</span>
          <span class="listing-tag">Dine-In</span>
          <span class="listing-tag">Take Away</span>
        </div> -->

            <!-- <div class="star-rating">
          <p>4.9</p>
          <img src="../static/Fullstar.png" alt="" />
          <img src="../static/Fullstar.png" alt="" />
          <img src="../static/Fullstar.png" alt="" />
          <img src="../static/Fullstar.png" alt="" />
          <img src="../static/Halfstar.png" alt="" />
          <p>(2)</p>
        </div> -->

            <div class="description">
              <div v-html="htmlContent"></div>
              <!-- <p><u style="color: #FF1613; font-weight: 500;">See More</u></p> -->
            </div>

            <!-- <ReviewComment /> -->
            <!-- <ReviewForm /> -->
          </div>

          <div class="col-lg-3">
            <h4>Latest Posts</h4>
            <div class="lpr-div" v-for="(post, index) in postList" :key="index">
              <nuxt-link :to="'/' + post.slug + '/'" class="nav-link">
                <table>
                  <tr>
                    <td>
                      <img :src="post.img" alt="shisha" style="width: 80px" />
                    </td>
                    <td>
                      <h5>{{ post.title }}</h5>
                      <span>View Details</span>
                    </td>
                  </tr>
                </table>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import { ref, onMounted, useHead } from 'vue';
import { useRoute } from 'vue-router';
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import Carousel from '@/components/Listing/Carousel.vue';

export default {
  name: 'PostDetail',
  components: {
    BaseHeader,
    BaseFooter,
    Carousel,
  },
  data() {
    return {
      categorySlug: this.$route.params.slug,
      categoriesLIst: [{ id: 1, title: 'My journey with Vue' }],
      post_images2: '',
    };
  },
  setup() {
    const route = useRoute();
    const pageTitle = ref('');
    const metaTitle = ref('');
    const metaDescription = ref('');
    const metaUrl = ref('');
    const htmlContent = ref('');
    const post_images = ref('');
    onMounted(async () => {
      const slug = route.params.slug;
      //console.log(slug);
      try {
        const response = await fetch(this.$apiUrl + 'getpostdetail/' + slug, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        //const response = await fetch('/api/getpostdetail?slug=' + slug);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        pageTitle.value = data[0].title;
        console.log(data[0].wp_id, 'zain');
        metaTitle.value = data[0].meta_title;
        metaDescription.value = data[0].meta_description;
        metaUrl.value = data[0].yoast_slug;
        //let abc = data[0].content;
        htmlContent.value = data[0].content;
        htmlContent.value = htmlContent.value
          .replace(new RegExp('\\\\n', 'g'), '')
          .replace(new RegExp('\\\\', 'g'), '');
        post_images.value = data[0].img;
        // this.post_images2 = data[0].img;
        //console.log(images.value, 'zz);

        // console.log(htmlContent.value);
        //console.log(data['data'], 'zain');
        //this.error = null;
        useHead({
          title: pageTitle.value,
          meta: [
            {
              property: 'og:locale',
              content: 'en_US',
            },
            {
              property: 'og:type',
              content: 'article',
            },
            {
              property: 'og:title',
              content: metaDescription.value,
            },
            {
              property: 'og:description',
              content: metaDescription.value,
            },
            {
              property: 'og:url',
              content: metaUrl.value,
            },
            {
              property: 'og:site_name',
              content: 'My Halal Restaurants',
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    });
    return {
      htmlContent,
      pageTitle,
      post_images,
    };
  },
  mounted() {
    //this.getCategoryList();
    this.getLatestPosts();
  },
  methods: {
    async getLatestPosts() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(
          this.$apiUrl + 'getlatestpostsfordetailpage',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.postList = data;
        //console.log(data['data'], 'zain');
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
    async getCategoryList() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(
          this.$apiUrl + 'getpostdetail/' + this.categorySlug,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.categoriesLIst = data[0];
        //console.log(this.categoriesLIst.title, 'zain');
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
strong a {
  color: #ff1613 !important;
}
.description a {
  color: #ff1613 !important;
}
</style>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.lpr-div {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  table {
    margin-top: 20px;
    background-color: #ffffff11;
    h5 {
      font-size: 13px;
      margin-left: 5px;
      margin-right: 5px;
      padding-top: 5px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }
    span {
      background-color: #dc354599;
      padding: 2px;
      padding-right: 20px;
      padding-left: 20px;
      border-radius: 10px;
      font-size: 11px;
      margin-left: 5px;
      margin-bottom: 5px;
      text-align: center;
      display: block;
    }
    span:hover {
      animation-duration: 0.5s;
      background-color: #dc3545;
    }
  }
}
.details {
  background-color: #000;
  width: 100%;

  .row {
    margin-top: 60px;
  }

  .details-text {
    margin-left: 10px;
  }
  .details-title {
    margin-left: 10px;
  }
  .details-icon {
    margin-left: 10px;
  }
}

.listing-tags {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.listing-tag {
  margin-right: 10px;
  padding: 5px;
  border: solid 1px #ff1613;
  border-radius: 10px;
  font-size: 12px;
}
.star-rating {
  display: flex;
  width: 15px;
  height: 25px;
  margin-left: 10px;
  p {
    font-size: 18px;
  }
}
.description {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 60px;
  img {
    width: 100% !important;
  }
  .size-full {
    width: 100% !important;
  }
}
.carousel {
  display: flex;
}
</style>
