<template>
  <div class="listingsection">
    <div class="listingtext">
      <h2>Check Out Our Recommended Places!</h2>
      <p>
        Explore some of the best Halal Places in Canada with
        MyHalalrestaurants.com
      </p>
    </div>

    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="(list, index) in latestListings"
          :key="index"
        >
          <router-link :to="'/listing/' + list.slug + '/'" class="nav-link">
            <div class="listingcards">
              <img class="listing_img" :src="list.img" alt="shisha" />
              <div class="card-body">
                <h4 class="card-title">{{ list.title }}</h4>
                <p class="card-text"></p>
              </div>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <img
                    class="listing-icon"
                    src="../../assets/CardCategory.png"
                  />
                </div>
                <span class="listing-text">Restaurant</span>
              </div>
              <div class="listing-tags">
                <!-- <span class="listing-tag">Pakistani</span>
                <span class="listing-tag">BBQ</span>
                <span class="listing-tag">Dine-In</span>
                <span class="listing-tag">{{ list.categories }}</span> -->
                <spn
                  class="listing-tag"
                  v-for="(catlist, index) in stringToArray(list.categories)"
                  :key="index"
                >
                  {{ catlist }}
                </spn>
              </div>
              <div class="input-group mb-2">
                <div class="listing-location">
                  <img class="mapIcon" src="../../assets/map.png" alt="" />{{
                    list.address
                  }}
                </div>
              </div>
              <hr class="listing-hr" />
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="lr">
                    <img src="../../assets/5star.png" alt="" />
                  </div>
                </div>
                <span class="listing-rating">{{ list.rating }}</span>
              </div>

              <!-- <div class="listing-hours">
                <span class="listing-text">Closed Now</span>
              </div> -->
              <div class="listing-hours">
                <span class="listing-text"
                  >Reviews ({{ list.review_count }})</span
                >
              </div>
            </div>
          </router-link>
        </div>

        <!-- <div class="col-lg-4 col-md-6 bg-dark">
                    <div class="listingcards">
                        <img src="../static/Rolls.png" alt="rolls">
                        <div class="card-body">
                            <h4 class="card-title">Lett'us Roll</h4>
                            <p class="card-text"></p>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img class="listing-icon" src="/static/CardCategory.png" />
                            </div>
                            <span class="listing-text">Restaurant</span>
                        </div>
                        <div class="listing-tags">
                            <span class="listing-tag">Pakistani</span>
                            <span class="listing-tag">BBQ</span>
                            <span class="listing-tag">Dine-In</span>
                            <span class="listing-tag">Take Away</span>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img src="../../assets/map.png" alt="">
                            </div>
                            <span class="listing-location">2683, Lawrence Avenue
                                East, ON CA</span>
                        </div>
                        <hr class="listing-hr">
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="lr">
                                    <img src="../../assets/star.png" alt="">
                                </div>
                            </div>
                            <span class="listing-rating">5.0</span>
                        </div>

                        <div class="listing-hours">
                            <span class="listing-text" style="color: #00C008;">Open Now</span>
                        </div>
                    </div>

                </div>

                <div class="col-lg-4 col-md-6 bg-dark">
                    <div class="listingcards">
                        <img src="../../assets/Sweets.png" alt="sweets">
                        <div class="card-body">
                            <h4 class="card-title">Al-Karam Sweets</h4>
                            <p class="card-text"></p>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img class="listing-icon" src="/static/CardCategory.png" />
                            </div>
                            <span class="listing-text">Bakery</span>
                        </div>
                        <div class="listing-tags">
                            <span class="listing-tag">Pakistani</span>
                            <span class="listing-tag">BBQ</span>
                            <span class="listing-tag">Dine-In</span>
                            <span class="listing-tag">Take Away</span>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img src="../../assets/map.png" alt="">
                            </div>
                            <span class="listing-location">2683, Lawrence Avenue
                                East, ON CA</span>
                        </div>
                        <hr class="listing-hr">
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="lr">
                                    <img src="../../assets/star.png" alt="">
                                </div>
                            </div>
                            <span class="listing-rating">4.7</span>
                        </div>
                        <div class="listing-hours">
                            <span class="listing-text">Closed Now</span>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListingCards',
  data() {
    return {
      listingSlug: this.$route.params.slug,
      latestListings: [{ id: 1, title: 'My journey with Vue' }],
    };
  },
  mounted() {
    this.getLatestListings();
  },
  methods: {
    stringToArray(string) {
      console.log(typeof string);
      if (typeof string == 'string') {
        return string.split(',');
      } else {
        return string;
      }
      // const str = "value1,value2,value3";
      // const arr = [];
      // let temp = '';

      // for (const char of string.toString()) {
      //   if (char === ',') {
      //     arr.push(temp);
      //     temp = '';
      //   } else {
      //     temp += char;
      //   }
      // }
      // if (temp) arr.push(temp);
      // // return string.split(',');
      // console.log(string);
      // return arr;
      // return string;
      // return string.match(/[^,]+/g);
    },
    async getLatestListings() {
      //alert(this.categoryDDModel);
      //console.log(runtimeConfig.public.apiBase);
      try {
        const response = await fetch(this.$apiUrl + 'getlatestlistings', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.latestListings = data;
        // console.log(data, 'zain1234');
        this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data';
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.listingsection {
  background-color: #000;
  height: auto;
  padding-bottom: 50px;

  .listingtext {
    padding-top: 50px;

    h2,
    p {
      color: #ff1613;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      font-style: medium;
      text-align: center;
    }

    p {
      color: #fff;
      font-weight: 200;
    }
  }

  .listingcards {
    position: relative;
    background: rgba(72, 72, 72, 0.5);
    border-radius: 6px;
    padding: 30px;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
    filter: drop-shadow(0 0 0.75rem #333);
    .listing_img {
      min-height: 250px;
    }
    .mapIcon {
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
      margin-right: 5px;
    }
    img {
      width: 100%;
      object-fit: cover;
      margin-bottom: 30px;
    }

    .card-title {
      text-align: left;
      font-weight: 400;
      // font-size: 18px;
    }
  }

  .listing-icon {
    margin-top: 10px;
  }

  .listing-text {
    margin-top: 13px;
    padding-left: 10px;
    font-weight: 300;
  }

  .listing-tags {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .listing-tag {
    padding: 5px;
    border: solid 1px #ff1613;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 200;
  }

  .listing-location {
    padding-left: 10px;
    margin-top: 2px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 14px;
  }

  .listing-hr {
    margin-top: -15px;
  }

  .listing-rating {
    margin-top: -3px;
    padding-left: 10px;
  }

  .listing-hours {
    float: right;
    margin-top: -55px;
    // color: #ff1613;
    color: #22f12ccc;
    font-weight: 300;
    font-size: 14px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1199px) {
    .listing-location {
      margin-top: -55px;
      padding-left: 30px;
    }
  }

  @media only screen and (min-device-width: 300px) and (max-device-width: 397px) {
    .listing-location {
      margin-top: -55px;
      padding-left: 30px;
    }
  }
}
</style>
